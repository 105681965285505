import React from 'react';
import styles from "./SupportToCauseItem.module.css";
const SupportToCauseItem = ({item}) => {
    return (
      <React.Fragment>
        {
          item && item.href ?
          <a href={item.href} target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}>
            <div className={styles.img1}>
              <img className={styles.img1Background} src={item.image} alt=""/>
              <div className={styles.logoFotoO}></div>
            </div>
          </a>
          :
          <div className={styles.img1}>
            <img className={styles.img1Background} src={item.image} alt="" />
            <div className={styles.logoFotoO}>
            </div>
          </div>
        }
       </React.Fragment>
    );
};


export default SupportToCauseItem;