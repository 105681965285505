import React from 'react'
import Box from '@mui/material/Box'
import TelegramIcon from '@mui/icons-material/Telegram';



const  FloatBtn = () =>{
    const handleOpenTelegram = () =>{
        window.open('https://bit.ly/3mZBOBS', '_blank');
    }

    return(
        <Box 
            sx={{
                width: '72px',
                height: '72px',
                backgroundColor: '#1976d2 !important',
                borderRadius: '50%',
                position: 'fixed',
                bottom: '15px',
                right: '15px',
                zIndex: '99 !important',
                cursor: 'pointer',
                "&:hover": {
                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
                }
               

            }}
            onClick={handleOpenTelegram}
        >
            <Box
                sx={{
                    width: '72px',
                    height: '72px',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center'
                }}
            >
                <TelegramIcon htmlColor='#fff' sx={{fontSize:'40px'}} />    
            </Box>
            
        </Box>
    )
}

export default FloatBtn;
