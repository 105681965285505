import styles from "./TermsAndConditions.module.css";
import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { config } from '../services/Sanity/sanity';
import Block from "components/Block";


const TermsAndConditions = () => {
  const [termsConditions, setTermsConditions] = useState(null);

  useEffect(() => {
    async function startFetching() {
      const sanityResult = await config()
      if (!ignore) {
        setTermsConditions(sanityResult.termsAndConditions);
      }
      console.log("Terms and conditions: ", sanityResult.termsAndConditions);
    }
    let ignore = false;
    startFetching();
    return () => {
      ignore = true;
    }
  }, []);

  return (
    <div>
      <Header />
      <div className={styles.sectionabout}>
        {termsConditions &&
          <>
          <div className={styles.title}> 
            { termsConditions.title }
          </div>
          <div className={styles.description}>
            <Block text={ termsConditions.description }/>
          </div>
          </>
        }
      </div>
      <Footer />
    </div>
  );
}

export default TermsAndConditions;