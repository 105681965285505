import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Sync from "components/Syn";
import Store from "./pages/Store";
import PageProfile from "./pages/PageProfile";
import Home from "./pages/Home";
import TermsAndConditions from "./pages/TermsAndConditions";
import { useEffect, useState } from "react";
import CollectionContext from "./context/CollectionContext";
import NFTSContext from "./context/NFTSContext";
import NFTpage from "./pages/NFTpage";
import { find } from "./services/collection";
import { addSalesCountToEach, findAllByProjectKey } from "./services/nfts";
import UserContext from "./context/UserContext";import { appInfo } from "services/app";
import PriceContext from "context/PriceContext";
import { price, sales } from "services/blockchain";
import Process from "./Process";


let internalListenApp = null;
function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const [collection, setCollection] = useState(null);
  const [nfts, setNFTS] = useState([]);
  const [user, setUser] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [ currentPrice, setCurrentPrice] = useState({ eth : 0, mxn : 0});

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

   const runInterval = () => {
    internalListenApp = setInterval(async() => {
      if(collection) {
        if(!pathname.includes('sync')) {
          let nfts = await findAllByProjectKey(process.env.REACT_APP_COLLECTION)
          try {
            const res = await sales(null,process.env.REACT_APP_COLLECTION,null);
            console.log('res', res)
            for (let index = 0; index < nfts.length; index++) {
              nfts[index].sales = res;
            }
          } catch (error) {
            console.log('error', error)
          }
          setNFTS(nfts)
          appInfo().then((data) => {
            if(data[process.env.REACT_APP_DOMAIN]) {
              if(data[process.env.REACT_APP_DOMAIN][collection.key]['reload']) {
                window.location.reload();
              }
            }
          })
        }
      }
    }, 30000)
   }
  useEffect(() => {
    if(!internalListenApp && collection) {
      runInterval()
    }
  }, [collection,internalListenApp]);

  useEffect(() => {
    const getCollection = async() =>{
      let collection = await find(process.env.REACT_APP_COLLECTION)
      let nfts = await findAllByProjectKey(process.env.REACT_APP_COLLECTION)
      try {
        nfts = await addSalesCountToEach(nfts);
      } catch (error) {
        console.log('error', error)
      }
      setNFTS(nfts)
      setCollection(collection)
      setIsReady(true)
      try {
        const cPrice = await price(null, collection.key, collection.rpc);
        console.log('price 2', cPrice)
        setCurrentPrice(cPrice)
        console.log(nfts);
      } catch (error) {
        console.log('error price', error)
      }
    }
    getCollection()
  },[]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/store":
        title = "";
        metaDescription = "";
        break;
      case "/pageprofile":
        title = "";
        metaDescription = "";
        break;
      case "/home":
        title = "";
        metaDescription = "";
        break;
      case "/terms-and-conditions":
        title="";
        metaDescription = ""
        break
      default:
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  if (!isReady) {
    return <Process title="Cargando" message="Cargando experiencia" />
  }

  return (
    <PriceContext.Provider value={{ currentPrice, setCurrentPrice }}>
      <UserContext.Provider value={{ user, setUser }}>
        <NFTSContext.Provider value={{ nfts, setNFTS }}>
          <CollectionContext.Provider value={{ collection, setCollection }}>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/home" element={<Home />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/nft" element={<NFTpage />} />
              <Route path="/store" element={<Store />} />
              <Route path="/profile" element={<PageProfile />} />
              <Route path="/sync" element={ <Sync/>} />
            </Routes>
          </CollectionContext.Provider>
        </NFTSContext.Provider>
      </UserContext.Provider>
    </PriceContext.Provider>
  );
}
export default App;
