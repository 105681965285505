import axios from "axios";

const url = process.env.REACT_APP_API+"collection?domain="+process.env.REACT_APP_DOMAIN

export const findAllByDomain = (domain) => {
    return axios.get(url+"?domain="+domain,{
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_X_API_KEY    
        }
    }).then(res=>res.data)
}

export const findAllByCreator = async(domain, wallet) => {
    return axios.get(url+"?domain="+domain+"&creator="+wallet+"&order=created&limit=30&page=0",{
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_X_API_KEY    
        }
    }).then(res=>{
       let collections = []
       res.data.forEach((collection) => {
        console.log(collection.blockchain)
            collections.push({
                name : collection.name,
                thumb_url : collection.thumb_url,
                key : collection.project_key,
                domain : collection.domain,
                blockchain : collection.blockchain.blockchain_name,
                explorer : collection.blockchain.explorer,
                nfts : collection.collection_of,
            })
       })
        return collections
    })
}

export const findAllByCreatorEmpty = async(domain, wallet) => {
    let collections = await findAllByCreator(domain,wallet)
    let emptyCollections =  []
    for (let index = 0; index < collections.length; index++) {
        const element = collections[index];
        if(element.nfts === 0) {
            emptyCollections.push(element)
        }
    }
    return emptyCollections
}

export const findAllWithNFTs = async(domain, wallet) => {
    let collections = await findAllByCreator(domain,wallet)
    let withNFTs =  []
    for (let index = 0; index < collections.length; index++) {
        const element = collections[index];
        if(element.nfts > 0) {
            withNFTs.push(element)
        }
    }
    return withNFTs
}

export const create = (collection) => {
    return axios.post(`${process.env.REACT_APP_API}/collection?domain=${collection.domain}`,collection)
    .then((success) => { return success }).catch((error) => {
        console.log(error)
    })
}

export const find = async(key) => {
    return axios.get(url+"&address="+key+"&order=created&limit=30&page=0",{
    headers: {
        'X-API-Key': process.env.REACT_APP_X_API_KEY    
    }
}).then(res=>{
       let collections = []
       res.data.forEach((collection) => {
            collections.push({
                name : collection.name,
                description : collection.description,
                thumb_url : collection.thumb_url.replace('https://persea.mypinata.cloud/ipfs/','https://images.persea.app/extra_files/'),
                banner : collection.banner_url.replace('https://persea.mypinata.cloud/ipfs/','https://images.persea.app/extra_files/'),
                key : collection.project_key,
                domain : collection.domain,
                blockchain : collection.blockchain.blockchain_name,
                explorer : collection.blockchain.explorer,
                rpc : collection.blockchain.rpc
            })
       })
        return collections[0]
    })
}
