import styles from "./ArtistSocial.module.css";
import {  IconLink } from "../Social/ArtistSocial";

const ArtistSocial = ({ socials }) => {

  return (
    <div className={styles.iconos}>
      {
        socials && socials?.map((item,index)=>{
          return (
            item && item.href &&  item.image && item.name &&
            <IconLink key={index} link={item.href} image={item.image}  name={item.name} />
          )
        })
      }
      {/* <Twitter className={styles.icon}/>
      <Instagram className={styles.icon}/>
      <Facebook className={styles.icon}/> */}
    </div>
  );
}

export default ArtistSocial;