import React from 'react';
import { benefits, donation } from '../services/Sanity/sanity';
import CarouselCustom from "./Carousel/CarouselCustom";
// import { serializers } from '../services/serializers';
import styles from "./GroupCarousel.module.css";
const BlockContent = require('@sanity/block-content-to-react')


const GroupComponent = ({typeDevice}) => {

  const [ openBenefits, setOpenBenefits ] = React.useState(false);
  const [ activeFirst, setActiveFirst] = React.useState(false);
  const [ openClassicWithCause, setOpenClassicWithCause] = React.useState(false);
  const [ benefit, setBenefit ] = React.useState(null);
  const [ donationAbout, seDonationAbout ] = React.useState(null);
 

  const handleOpenBenefits = () => {
    setActiveFirst(true);
    setOpenBenefits(true);
    setOpenClassicWithCause(false);
  }

  const handleClassicWithCause = () => {
    setActiveFirst(true);
    setOpenBenefits(false);
    setOpenClassicWithCause(true);
  }

  const getBenefits = async () =>{
    try {
      const res = await benefits();
      setBenefit(res);
    } catch (error) {
      console.error(error);
    }
  }

  const getAboutDonations = async () =>{
    try{
      const res = await donation();
      if(res && res.length > 0){
        seDonationAbout(res[0]);
      }
    }catch(err){
      console.log(err);
    }
  }

  React.useEffect(() => {
    getBenefits();
    getAboutDonations();
  },[]);


  return ( benefit &&
    <div className={styles.carouelesParent}>
      <div className={styles.caroueles}>
        <div className={styles.property1closed}>
          <div className={styles.buttonsWrapper}>
            <div className={styles.buttons}>
              <div onClick={handleOpenBenefits} className={!openBenefits && activeFirst ? `${styles.boton} ${styles.active}` : styles.boton} >
                <div className={styles.adquirirAhora}>Beneficios</div>
              </div>
              <div onClick={handleClassicWithCause} className={!openClassicWithCause && activeFirst? `${styles.boton1} ${styles.active}` : styles.boton1}>
                <div className={styles.adquirirAhora}>Clásico con Causa</div>
              </div>
            </div>
          </div>
        </div>
        <div className={activeFirst ? `${styles.contentCarousel} ${styles.contentCarouselActive}`: `${styles.contentCarousel}`}>
          {
            activeFirst &&
            <React.Fragment>
              <div  className={openBenefits  ? `${styles.property1beneficios} ${styles.property1beneficiosActive}` : `${styles.property1beneficios}`}>
                <div className={openBenefits ? `${styles.buttonsParent} ${styles.buttonsParentActive}` : `${styles.buttonsParent}`}>
                  <div className={styles.panelBeneficios1}>
                    <div className={styles.text}>
                      <b className={styles.losBeneficiosSern}>
                        {benefit.title}
                      </b>
                      <BlockContent  blocks={ benefit.description } className={styles.loremIpsumDolor} />
                    </div>
                    <div className={styles.elClsicoDeLosBeneficios}>
                    <div className={styles.losBeneficiosSern1}>
                      {
                        benefit && benefit.disclaimer && 
                        <BlockContent  blocks={ benefit.disclaimer } className={styles.loremIpsumDolor} />
                      }
                      </div> 
                    </div>
                    {/* init carousel items */}
                    <div className={styles.beneficios}>
                      { benefit && benefit.benefits && benefit.benefits.length > 0 &&
                       <CarouselCustom 
                        items={benefit.benefits}
                        typeDevice={typeDevice} 
                        typeCard="benefits"
                        />
                      }
                    </div>
                    {/* <div className={styles.dots}>
                      <img
                        className={styles.galleryDotIcon}
                        alt=""
                        src="../gallery-dot.svg"
                      />
                      <img
                        className={styles.galleryDotIcon1}
                        alt=""
                        src="../gallery-dot1.svg"
                      />
                      <img
                        className={styles.galleryDotIcon1}
                        alt=""
                        src="../gallery-dot1.svg"
                      />
                      <img
                        className={styles.galleryDotIcon1}
                        alt=""
                        src="../gallery-dot1.svg"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className={openClassicWithCause ? `${styles.property1beneficios} ${styles.property1beneficiosActive}` : `${styles.property1beneficios}`}>
                <div className={openClassicWithCause ? `${styles.buttonsParent} ${styles.buttonsParentActive}` : `${styles.buttonsParent}`}>
                  <div className={styles.panelBeneficios2}>
                    <div className={styles.text}>
                      <b className={styles.losBeneficiosSern}>
                        {donationAbout && donationAbout.title && donationAbout.title}
                      </b>
                      <BlockContent  blocks={ donationAbout.description } className={styles.loremIpsumDolor} />
                    </div>
                    <div className={styles.beneficios1}>
                      {
                        (typeDevice === "mobile" || typeDevice === "tablet" )?
                        <React.Fragment>
                          {  donationAbout && donationAbout.donations && donationAbout.donations.length > 0 &&
                            <CarouselCustom 
                              items={donationAbout.donations}
                              typeDevice={typeDevice} 
                              typeCard="img"
                            />
                          }
                        </React.Fragment>
                        :
                        <React.Fragment>
                        { donationAbout && donationAbout.donations && donationAbout.donations.length > 0 &&
                          donationAbout.donations?.map((item,index)=>{
                            return (
                              <React.Fragment key={index} >
                                {
                                  item && item.href ?
                                  <a href={item.href} target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}>
                                    <div className={styles.img1} style={{ height: '106px'}}>
                                      <img className={styles.img1Background} src={item.image} />
                                      <div className={styles.logoFotoO}></div>
                                    </div>
                                  </a>
                                  :
                                  <div className={styles.img1} style={{ height: '106px'}}>
                                    <img className={styles.img1Background} src={item.image} />
                                    <div className={styles.logoFotoO}>
                                    </div>
                                  </div>
                                }
                              </React.Fragment>
                             
                            )
                          })}
                        </React.Fragment>
                      }                     
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    </div>
  );
};

export default GroupComponent;
