import Dialog from '@mui/material/Dialog';
import Store from '../pages/Store';
import Footer from './Footer';
import Header from './Header';

const ModalGallery = ({ onClose, selectedValue, open }) => {

  return (
    <Dialog open={open} fullScreen>
        <Header />
        <Store onClose= {onClose}/>
        <Footer />
    </Dialog>
  );
};

export default ModalGallery;