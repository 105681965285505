import { useMemo } from "react";
import styles from "./Benefit.module.css";

const Benefit = ({
  boletosDoblesEnLosSiguienColor,
  estadioAKRON,
  tresClsicosNacionalesVaro,
}) => {
  const boletosDoblesEnContainerStyle = useMemo(() => {
    return {
      color: boletosDoblesEnLosSiguienColor,
    };
  }, [boletosDoblesEnLosSiguienColor]);

  return (
    <div className={styles.beneficio}>
      <div className={styles.boletosDoblesEnLosSiguientParent}>
        <div
          className={styles.boletosDoblesEnContainer}
          style={boletosDoblesEnContainerStyle}
        >
          {/* <p
            className={styles.boletosDoblesEn}
          >{`3 boletos dobles en los siguientes `}</p> */}
          <p className={styles.tresClsicosNacionales}>
            {tresClsicosNacionalesVaro}
          </p>
        </div>
        <div className={styles.estadioAkron}>{estadioAKRON}</div>
      </div>
    </div>
  );
};

export default Benefit;
