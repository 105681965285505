import React, { useContext } from 'react';
import { useState, useCallback } from "react";
import IlustracinZoom from "../components/IlustracinZoom";
import PortalPopup from "../components/PortalPopup";
import CustomButton from "../components/CustomButton";
import styles from "./AboutArt.module.css";
import GroupCarousel from '../components/GroupCarousel';
import CollectionContext from '../context/CollectionContext';
import NFTSContext from '../context/NFTSContext';
import { about } from '../services/Sanity/sanity';
import ModalGallery from "../components/ModalGallery";
import Block from './Block';



const handleResize = (size) => {
  if(size < 600){
      return "mobile";
  }else
  if(size >= 600 && size < 900){
      return "tablet";
  }else
  if(size >= 900 && size < 1440){
      return "desktop"
  }else
  if(size >= 1140 && size < 2879){
      return "desktop"
  }else
  if(size >= 2880){
      return "big";
  }
}

const AboutArt = () => {
  const { collection } = useContext(CollectionContext);
  const { nfts } = useContext(NFTSContext);
  const [ newAbout, setNewAbout ] = React.useState(null);
  const [ openGallery , setOpenGallery ] = React.useState(false);
  const [ hiddeBuyButton , setHideBuyButton ] = React.useState(false);
  const [ isIlustracinZoomOpen, setIlustracinZoomOpen ] = useState(false);
  let width = window.innerWidth;
  const [typeDevice,setTypeDevice] = React.useState(handleResize(width));

  const openIlustracinZoom = useCallback(() => {
    setIlustracinZoomOpen(true);
  }, []);

  const closeIlustracinZoom = useCallback(() => {
    setIlustracinZoomOpen(false);
  }, []);

  const getAboutArt = async () =>{
    try {
      const res = await about();
      setNewAbout(res);
    } catch (error) {
      console.error(error);
    }
  }

  const countSales = async () =>{
    try {
      if(nfts && nfts.length > 0 && nfts[0].sales) {
        console.log('sales using nfts',  nfts[0].sales)
        if(nfts[0].sales >= 10) {
          setHideBuyButton(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleGo = () => {
    setOpenGallery(true)
  }

  React.useEffect(() => {
    getAboutArt();
  },[]);

  React.useEffect(() => {
    countSales();
  },[nfts]);

  React.useEffect(() => {
    window.addEventListener("resize", function(){
        width = window.innerWidth;
        setTypeDevice(handleResize(width));
    });

    window.removeEventListener("resize",function(){
        width = window.innerWidth;
        setTypeDevice(handleResize(width));
    });
  },[width]);


  return ( collection && nfts && newAbout &&
    <React.Fragment>
      <div className={styles.coleccin}>
        <img className={styles.bgIcon} alt="" src={collection.banner}/>
        <div className={styles.body}>
          <div style={{ display: 'flex',flexDirection:'column', gap: '16px' }}>
            <b className={styles.titulo}>{newAbout.title}</b>
            <Block   text={ newAbout.description } />
          </div>
          <div className={styles.ilustracin} onClick={openIlustracinZoom}>
            <div className={styles.imgParent}>
              <img className={styles.imgIcon} alt="" src={ collection.thumb_url } />
            </div>
          </div>
          <GroupCarousel typeDevice={typeDevice}/>
          <div className={styles.text1}>
            <label className={styles.espacioOpcionalPara}>
              {
                newAbout && newAbout.disclaimer && !hiddeBuyButton &&
                <Block   text={ newAbout.disclaimer } />
              }
            </label>
            { 
              <CustomButton  handleGo={handleGo}  showBuy = {hiddeBuyButton}/>
            }
            { hiddeBuyButton &&
              <div className={styles.soldout}>
                <b className={styles.soldOut}>SOLD OUT</b>
              </div>
            }
          </div>
        </div>
        <ModalGallery open = {openGallery} onClose = {setOpenGallery}/>
      </div>
      {isIlustracinZoomOpen === -1 && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeIlustracinZoom}
        >
          <IlustracinZoom onClose={closeIlustracinZoom} image={ nfts[0].thumb_url } />
        </PortalPopup>
      )}
    </React.Fragment>
  );
};

export default AboutArt;
