import React from "react";
import styles from "./SectionTeams.module.css";
import { teams } from '../services/Sanity/sanity';

const SectionTeams = () => {
  const [ aboutTeams,setAboutTeams ] = React.useState(null);
  const getAboutTeams = async () =>{
    try {
      const res = await teams();
      setAboutTeams(res);
    } catch (error) {
      console.error(error);
    }
  }

  // const handleGo = () => {
  //   setOpenGallery(true)
  // }

  React.useEffect(() => {
    getAboutTeams();
  },[]);

  return (
    <div className={styles.sectionteams}>
      <div className={styles.frameParent}>
        {
          aboutTeams && aboutTeams.length > 0 &&
          aboutTeams?.map((item,index)=>{
            return (
              index < 2 &&
              <div key={index} className={index < 1 ? styles.logoAmeParent1:styles.logoAmeParent2}>
                <div className={styles.contentBody}>
                  <img className={styles.logoAmeIcon} alt="" src={item.brand} />
                  <div className={styles.discordParent}>
                    {
                      item && item.socials && item.socials.length > 0 &&
                      item.socials?.map((item,index1)=>{
                        return (
                          <a key={index1} href={item.href} target="_blank" rel="noreferrer">
                            <img className={styles.twitterIcon} alt="" src={item.image} />
                          </a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            )
          })
          
        }
      </div>
    </div>
  );
};

export default SectionTeams;
