import React from 'react';
import Header from '../components/Header';
import NFTView1 from '../components/NFTView1';
import styles from "./NFTpage.module.css";
import Footer from '../components/Footer';
import NFTSContext from '../context/NFTSContext';
import CollectionContext from '../context/CollectionContext';
import { payment } from 'services/Sanity/sanity';

const NFTpage = () => {
  const { nfts } = React.useContext(NFTSContext);
  const { collection } = React.useContext(CollectionContext);
  const [ paymentSanity, setPaymentSanity ] = React.useState(null);
  
  const getPayment = async () =>{
    try {
      const res = await payment();
      if(res){
        setPaymentSanity(res);
      }
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getPayment()
  },[]);


  return (
    <React.Fragment>
        <Header />
        <div className={styles.coleccin}>
          {
            collection && collection.banner &&
            <img className={styles.bgIcon} alt="" src={collection.banner}/>
          }
          <div className={styles.content}>
              <div className={styles.desktop}>
                <NFTView1 nfts={nfts} paymentSanity={paymentSanity}/>
              </div>
          </div>
        </div>
        <Footer />
    </React.Fragment>
  );
};

export default NFTpage;
