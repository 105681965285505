import React from "react";
import ArtistInfo from "./ArtistInfo";
// import MakingOf from "./MakingOf";
import styles from "./SectionArtist.module.css";
import { artist } from '../../services/Sanity/sanity';

const SectionArtist = () => {

  const [ infoArtist, setInfoArtist ] = React.useState(null);
  const getInfoArtist = async () =>{
    try {
      const res = await artist();
      if(res && res.length > 0 && res[0]){
        console.log('artist',res[0])
        setInfoArtist(res[0]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getInfoArtist()
  },[]);
  return (
    <div className={styles.body}>
      <div className={styles.sectionartist}>
        <ArtistInfo artist={infoArtist}  />
        {/* <MakingOf artist={infoArtist} /> */}
      </div>
    </div>
  );
};

export default SectionArtist;
