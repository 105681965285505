import React from 'react';
/* imports para carousel */
import "react-multi-carousel/lib/styles.css";
/********/
import Carousel from "react-multi-carousel";
import Box from "@mui/material/Box";
import Benefit from 'components/Benefit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SupportToCauseItem from 'components/SupportToCauseItem';

const ButtonGroup = ({ next, previous,typeCard,...rest}) => {
    const { carouselState: { currentSlide,deviceType,totalItems,slidesToShow}} = rest;
  
    const [show,setShow] = React.useState(false);
    const [showBack,setShowBack] = React.useState(false);
    const [showNext,setShowNext] = React.useState(false);
    
    React.useEffect(()=>{
        if((deviceType === 'mobile' || deviceType === 'tablet')){
          setShow(true)
        }else{
          setShow(true)
        }
    },[deviceType])
  
  
    const checkDevice = () => {
      if(currentSlide > 0){
        setShowBack(true)
      }else{
        setShowBack(false)
      }
      if(currentSlide < (totalItems - slidesToShow)){
        setShowNext(true)
      }else{
        setShowNext(false)
      }
    }
  
    React.useEffect(()=>{
      const validate = () => {
        checkDevice();
      }
      validate();
  
    },[currentSlide,totalItems])
  
    return (
      <React.Fragment>
        {show ?
        <React.Fragment>
          {
            showBack &&
            <Box
           
              draggable="false"
              sx={{
                position: "absolute",
                top:'0px',
                left: "0px",
                width: "30px",
                height: "100%",
                backgroundColor: "transparent",
                color: "rgb(255,255,255)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px 0px 0px 10px"
              }}
            >
              <Box
                onClick={() => previous()}
                sx={{
                  WebkitUserSelect: 'none',
                  MozUserSelect: 'none',
                  KhtmlUserSelect: 'none',
                  msUserSelect:'none',
                  background: 'rgba(0,0,0,.3)',
                  height: '30px',
                  width: '30px',
                  borderRadius: '50%',
                  display:'flex',
                  justifyContent:'center',
                  alignItems: 'center',
                  cursor: "pointer"
                }}
              >
               <ArrowBackIosIcon 
                sx={{
                  fontSize: '18px'
                }}
               />
              </Box>
            </Box>
          }
          {
            showNext &&
            <Box
              
              sx={{
                position: "absolute",
                top:'0px',
                right: "0px",
                width: "30px",
                height: "100%",
                backgroundColor: "transparent",
                color: "rgb(255,255,255)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0px 10px 10px 0px"
              }}
            >
              <Box
                onClick={() => next()}
                sx={{
                  WebkitUserSelect: 'none',
                  MozUserSelect: 'none',
                  KhtmlUserSelect: 'none',
                  msUserSelect:'none',
                  background: 'rgba(0,0,0,.3)',
                  height: '30px',
                  width: '30px',
                  borderRadius: '50%',
                  display:'flex',
                  justifyContent:'center',
                  alignItems: 'center',
                  cursor: "pointer"
                }}
              >
               <ArrowForwardIosIcon 
                sx={{
                  fontSize: '18px'
                }}
               />
              </Box>
            </Box>
          }
          </React.Fragment>
          :
          null
        }
      </React.Fragment>
    );
};
  

const CarouselCustom = ({items,typeDevice,typeCard}) => {

    const responsive = {
        big:{
          breakpoint: { max: 4000, min: 2880},
          items: 3,
          paritialVisibilityGutter: 2
        },
        desktop: {
          breakpoint: { max: 2879, min: 900 },
          items: 3,
          paritialVisibilityGutter:0
        },
        tablet: {
          breakpoint: { max: 899, min: 600 },
          items: 2,
          paritialVisibilityGutter: 0
        },
        mobile: {
          breakpoint: { max: 600, min: 0 },
          items: 1,
          paritialVisibilityGutter: 0
        }
    };

    return (
      <Box
      component={Carousel}
      ssr={false}
      partialVisible
      deviceType={typeDevice}
      responsive={responsive}
      arrows={false}
      showDots={true}
      renderButtonGroupOutside={false}
      customButtonGroup={<ButtonGroup  />}
      sx={{
        width:'100%',
        height: 'auto',
        "&>ul>li>button":{
          backgroundColor:'#001429',
          opacity:'0.3',
          border: 'none'
        },
        "&>ul>.react-multi-carousel-dot--active>button":{
          backgroundColor:'#001429',
          opacity:'1',
          border: 'none'
        },
        "&>ul.react-multi-carousel-track":{
          
          height:'225px',
          gap: '0px'
        },
        "@media screen and (max-width:1250px)":{
          "&>ul.react-multi-carousel-track":{
            gap: '20px'
          }
        },
        "@media screen and (max-width:1180px)":{
          "&>ul.react-multi-carousel-track":{
            gap: '40px'
          }
        },
        "@media screen and (max-width:1130px)":{
          "&>ul.react-multi-carousel-track":{
            gap: '50px'
          }
        },
        "@media screen and (max-width:1080px)":{
          "&>ul.react-multi-carousel-track":{
            gap: '5px'
          }
        }
      
      }}
    >

        {
            items?.map((item,index)=>{
            return (
              <React.Fragment key={index}>
                {
                  typeCard === "benefits" ?
                  <Benefit
                    key={index}
                    boletosDoblesEnLosSiguienColor={"#fff"}
                    estadioAKRON={item.title}
                    tresClsicosNacionalesVaro={item.description}
                  />
                  :
                  <SupportToCauseItem item={item} />
                }
              </React.Fragment>
             
            )
          })
        }
    </Box>
    );
};


export default CarouselCustom;