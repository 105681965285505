import Block from 'components/Block';
import React from 'react';
import styles from "./ArtistInfo.module.css";
import ArtistSocial from "./ArtistSocial";


const ArtistInfo = ({ artist }) => {

  return (
    <div className={styles.content}>
      <div className={styles.text}>
        <h2 className={styles.titulo}>{artist && artist.name ? artist.name: ''}</h2>
        <p className={styles.loremIpsumDolorContainer}>
          <span className={styles.loremIpsumDolor}>
            {
              artist && artist.description && 
              <Block text = { artist.description} styleClass={styles.loremIpsumDolor}/>
            }
          </span>
        </p>
      </div>
      <div className={styles.imgIconWrapper}>
        {
          artist && artist.image && 
          <img className={styles.imgIcon} alt="" src={artist.image} />
        }
        <div className={styles.conoceMsDeSuTrabajoParent}>
          {
            artist && artist.socials &&
            <React.Fragment>
              <div className={styles.conoceMsDe}>Conoce más de su trabajo:</div>
              <ArtistSocial socials={artist.socials} />
            </React.Fragment>
          }
        </div>
      </div>
    </div>
  );
};

export default ArtistInfo;
