import React  from "react";
import Header from "../components/Header";
import SectionPresentation from "../components/SectionPresentation";
import SectionAbout from "../components/SectionAbout";
import AboutArt from "../components/AboutArt";
import SectionArtist from "../components/SectionArtist/SectionArtist";
import SectionTeams from "../components/SectionTeams";
import FAQs from "../components/FAQs";
import Footer from "../components/Footer";
/* import NFTInvoBenefits from "../components/NFTInvoBenefits"; */
import styles from "./Home.module.css";
import FloatBtn from "components/FloatBtn";

const Home = () => {



  return (
    <div className={styles.home}>
      <Header />
      <SectionPresentation />
      <SectionAbout />
      <AboutArt />
      <SectionArtist />
      <div className={styles.sections}>
        <SectionTeams />
        <FAQs />
      </div>
      <Footer/>
      <FloatBtn />
    </div>
  );
};

export default Home;
