import IconInstagram from "./Icons/IconInstagram";
import IconTwitter from "./Icons/IconTwitter";
import IconFacebook from "./Icons/IconFacebook";
import CardMedia from "@mui/material/CardMedia";

export const Instagram = ({...props}) => {
  return (
    <IconInstagram {...props}/>
  );
}

export const Twitter = ({...props}) => {
  return (
    <IconTwitter {...props}/>
  );
}

export const Facebook = ({...props}) => {
  return (
    <IconFacebook {...props}/>
  );
}

export const  IconLink = ({ image, link, name }) =>{
  return(
  <a href={link} target="_blank" rel="noreferrer" >
    <CardMedia
      component={"img"}
      alt={name}
      src={image}
      sx={{
        width: { xs: '21px', sm:'48px', md:'48px', lg:'48px', xl:'48px'},
        height: { xs: '21px', sm:'48px', md:'48px', lg:'48px', xl:'48px'}
      }}
    />
  </a>
  )
}